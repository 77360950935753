import React from "react";
import '../BoxesSection/index.scss';
import data from "./utills/data";
import { PrismicRichText } from "@prismicio/react";
import get from 'lodash/get'

const BoxesSection = ({slice}) => {
  return (
    <div className="boxes-section fadeIn">
     <div className="">
          <h3 className="section-center">
            {get(slice, "primary.title", "")} 
            <p>
              {get(slice, "primary.highlight", "")}
            </p>
          </h3>
        <div className="Container">
          <div className="box-container row g-0">
            {
              get(slice, "items", []).map((item, idx) => {
                return(
                  <div 
                    className={
                      `col-md-4 
                      ${
                        (idx == 0 ? 'column-1'
                        : (idx==1) ? 'column-2' 
                        : (idx==2) ? 'column-3' 
                        : (idx==3) ? 'column-2' 
                        : (idx==4) ? 'column-3' 
                        : 'colum-1' )
                      } single-box-card`} key={item.id} >
                    <div className="number">
                      {get(item, "number", "")}
                    </div>
                    <div className="title-1">
                      {get(item, "title", "")}
                    </div>
                    <div className="txt">
                      <PrismicRichText field={get(item, "description", [])}/>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
        </div>
        
    </div>
   
  );
};

export default BoxesSection;
