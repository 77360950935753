import React from "react";
import { Container } from "react-bootstrap";
import * as s from "./index.module.scss";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";

const RichTextTwo = ({ slice }) => {
  return (
    <section className="fadeIn">
      <div className={`${s.rich_text} section-center row`}>
        <div className="col-md-8">
          <PrismicRichText field={get(slice, "primary.rich_text", [])} />
        </div>
      </div>
    </section>
  );
};

export default RichTextTwo;
