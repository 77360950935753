import React, { useState } from "react";
import "../CTASection/index.scss";
import { FloatingLabel, Form } from "react-bootstrap";
import CallIcon from "../../../../images/BlogsPage/Vector.png";
import i18n from "../../../../../utils/i18n";
// import get from "lodash/get";
// import GetQuote from "../../../../images/Icons/get_quote_email.svg";
// import submitContactForm from "../../../../../utils/submitContactForm";

const CTA_Section = ({ slice = {} }) => {
  // const [user, setUser] = useState({
  //   // name: "",
  //   // email: "",
  //   phone: "",
  //   // company: "",
  //   // message: "",
  //   // type: "",
  //   // media_code: "Inme_Corporate",
  // });

  // // const [success, setSuccess] = useState(false);
  // // const [fail, setFail] = useState(false);
  // // const [progress, setProgress] = useState(false);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // setProgress(true);
  //   // let status = await submitContactForm(user);
  //   // let response = get(status, "formResponse.data", {});
  //   // let inquiryID = get(response, "inquiry_id", "");
  //   // if (inquiryID) {
  // //     setSuccess(true);
  // //     setProgress(false);
  // //     user.name = "";
  // //     user.email = "";
  // //     user.phone = "";
  // //     user.company = "";=]
  // //     user.message = "";
  // //     user.type = "";
  // //     setInterval(() => {
  // //       setSuccess(false);
  // //     }, 6000);
  // //   } else if (!inquiryID || status === 404) {
  // //     setFail(true);
  // //     setInterval(() => {
  // //       setFail(false);
  // //     }, 6000);
  // //   }
  // // };
  // }

  return (
    <div className="cta-section fadeIn">
      <div className="cta-section-wrapper">
        <div className="cta-form-wrapper">
          <Form>
            <Form.Group>
              <div className="cta-form-grid">
                <div>
                  <Form.Control
                    type="tel"
                    placeholder={`${i18n.t("Your Mobile Number")}`}
                  />
                </div>
                <div>
                  <button class="primary-btn">
                    <img src={CallIcon} alt="callicon" />
                    {i18n.t("Request Quote")}
                  </button>
                </div>
              </div>
            </Form.Group>
          </Form>
          <div class="sub-points">
            <div class="list">
              {slice?.items?.map((item, idx) => {
                return (
                  <p key={idx}>
                    {" "}
                    <span></span> {item?.cta_point}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="line"></div>
    </div>
  );
};

export default CTA_Section;
