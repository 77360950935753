import React from 'react'
import '../CardsSectionBottom/index.scss';
import CardsSection from '../../PersonalInsuarancePage/cards'

function CardsSectionBottom({slice}) {
  return (
    <div className='cards-section-bottom fadeIn'>
      <CardsSection seoBlog={true} slice={slice}/>
    </div>
  )
}

export default CardsSectionBottom
