import React from "react";
import "../Carousal/index.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";

const CarousalSection = ({ slice }) => {
  return (
    <>
      <div className="carousal fadeIn">
        <div className="section-center">
          <h3>{get(slice, "primary.carousel_title", "")}</h3>
          <div className="bottom-text">
            <PrismicRichText
              field={get(slice, "primary.carousel_subtitle", [])}
            />
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            // autoplay={true}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
              1440: {
                slidesPerView: 4,
              },
            }}
          >
            {get(slice, "items", []).map((item, idx) => {
              return (
                <SwiperSlide className="swiper-slide" key={idx}>
                  <img
                    src={item?.card_icon?.url}
                    alt={get(item, "card_icon.alt", "")}
                    class="icon"
                  />
                  <div className="st-one">{get(item, "card_title", "")}</div>
                  <div className="content">
                    <PrismicRichText field={get(item, "card_text", [])} />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="bottom-text">
            <PrismicRichText field={get(slice, "primary.carousel_text", [])} />
          </div>
          <div class="line"></div>
        </div>
      </div>
    </>
  );
};

export default CarousalSection;
