import React from "react";
import "../../../../styles/global.scss";
import "../Hero/index.scss";
import Form from "../Form/Form";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";

const BlogsPage = ({ data }) => {
  return (
    <>
      <section className="hero-container">
        <div class="Container section-center">
          <div class="row justify-content-between align-items-xl-center">
            <div class="col-xl-4 title-points-container">
              <PrismicRichText field={get(data, "hero_title", [])} />

              <div class="sub-points">
                <div class="list">
                  {get(data, "hero_points", []).map((item, idx) => {
                    return (
                      <p key={idx}>
                        <span></span> {get(item, "hero_point", "")}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-4">
              <img
                class="bg-image"
                src={get(data, "inme_image.url", "")}
                alt={get(data, "inme_image.alt", "")}
              />
            </div>

            <div class="col-md-6 col-xl-4 mt-4 mt-md-0">
              <Form />
            </div>
          </div>

          <div class="line"></div>
        </div>
      </section>
    </>
  );
};

export default BlogsPage;
