import React from "react";
import { Container } from "react-bootstrap";
import * as s from "./index.module.scss";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";

const RichText = ({ slice }) => {
  return (
    <section className="fadeIn">
      <div className={`${s.rich_text} section-center`}>
        <PrismicRichText field={get(slice, "primary.rich_text", [])} />
      </div>
    </section>
  );
};

export default RichText;
