import React from 'react'
import * as s from './index.module.scss'

function Seperator() {
  return (
    <section className="section-center fadeIn">
        <div className={s.line}></div>
    </section>
  )
}

export default Seperator