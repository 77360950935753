import React from "react";
import "../cards/index.scss";
import get from "lodash/get";
import { PrismicRichText } from "@prismicio/react";
import data from "../cards/utils/data";
import { useStaticQuery, graphql } from "gatsby";
import i18n from "../../../../../utils/i18n";

const CardsSection = ({ slice, seoBlog = false }) => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicSeoBlogPage {
        nodes {
          id
          uid
          url
          data {
            parent_page {
              url
              uid
            }
          }
        }
      }
    }
  `);

  return (
    <div className="cards">
      <div className="section-center">
        <div className="Container">
          <div
            className={`${
              seoBlog
                ? "content-section blog-content fadeIn"
                : "content-section fadeIn"
            }`}
          >
            <div className="title-two text-start text-md-center">
              {get(slice, "primary.cards_title", "")}
            </div>
            <div className="st-two text-start text-md-center">
              <PrismicRichText field={get(slice, "primary.cards_text", [])} />
            </div>
          </div>
          <div className="row">
            {get(slice, "items", []).map((item, idx) => {
              const url = data?.allPrismicSeoBlogPage?.nodes?.find(
                ({ uid }) => {
                  return uid === item?.button_link?.uid;
                }
              );
              const parentPageUid = url?.data?.parent_page?.uid;
              const pageUid = url?.uid;
              return (
                <div className="col-sm-6 col-xl-4 fadeIn" key={idx}>
                  <div className="card-container ">
                    <img
                      src={get(item, "card_image.url", "")}
                      class="card-img "
                    />
                    <p class="st-two">{get(item, "card_title", "")}</p>
                    <div className="content">
                      <PrismicRichText field={get(item, "card_text", [])} />
                    </div>
                    <a
                      href={`/${parentPageUid}/${pageUid}` || "#"}
                      class="primary-btn"
                      type="submit"
                    >
                      {i18n.t("Learn More")}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsSection;
